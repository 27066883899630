import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClassService {
  apiUrl = '';
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.apiUrl = baseUrl + 'api/class/';
  }


  getmyclasses() {
    return this.http.get<any>(this.apiUrl + 'classesList').pipe(map(data => {
      if (data) {
        return data;
      } else {
        return null;
      }
    }));
  }

  GetNextClassDetails() {
    return this.http.get<any>(this.apiUrl + 'GetNextClassDetails').pipe(map(data => {
      if (data) {
        return data;
      } else {
        return null;
      }
    }));
  }

  getteacherclasses(teacherId: string) {
    return this.http.get<any>(this.apiUrl + 'GetTeacherClasses?TeacherId=' + teacherId, {}).pipe(map(data => {
      if (data) {
        return data;
      } else {
        return null;
      }
    }));
  }
}

