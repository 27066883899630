import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ImportFile } from '../../_models/user';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  apiUrl = '';
  AuthapiUrl = '';
  myAccountapiUrl = '';
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.apiUrl = baseUrl + 'api/Student/';
    this.AuthapiUrl = baseUrl + 'api/Account/';
    this.myAccountapiUrl = baseUrl + 'api/MyAccount/';
  }

  updateProfile(model: any) {
    return this.http.post<any>(this.apiUrl + 'updateprofile', model).pipe(map(data => {
      return data;
    }));
  }
  updateImage(obj: ImportFile) {
    return this.http.post<any>(this.apiUrl + 'updateImage', obj.file).pipe(map(data => {
      return data;
    }));
  }
  profileDetail() {
    return this.http.get<any>(this.apiUrl + 'profiledetail').pipe(map(data => {
      return data;
    }));
  }
  fillBloodGroup() {
    return this.http.get<any>(this.apiUrl + 'fillBloodGroup').pipe(map(data => {
      return data;
    }));
  }
  fillDOBMonth() {
    return this.http.get<any>(this.apiUrl + 'fillDOBMonth').pipe(map(data => {
      return data;
    }));
  }
  fillDOBYear() {
    return this.http.get<any>(this.apiUrl + 'fillDOBYear').pipe(map(data => {
      return data;
    }));
  }
  fillCountry() {
    return this.http.get<any>(this.apiUrl + 'fillCountry').pipe(map(data => {
      return data;
    }));
  }
  GetTimeZoneList(countryId: string) {
    return this.http.get<any>(this.myAccountapiUrl + 'GetTimeZoneList?CountryId=' + countryId, {}).pipe(map(data => {
      return data;
    }));
  }

  ChangeUTCDate(timezone: string) {
    return this.http.get<any>(this.myAccountapiUrl + 'ChangeUTCDate?Timezone=' + timezone, {}).pipe(map(data => {
      return data;
    }));
  }
  getBonusClassList() {
    return this.http.get<any>(this.apiUrl + 'bonusClassList', {}).pipe(map(data => {
      return data;
    }));
  }
  SendNotification() {
    return this.http.get<any>(this.apiUrl + 'SendNotification', {}).pipe(map(data => {
      return data;
    }));
  }
  AddStudentNotificationToken(tokendata) {
    return this.http.post<any>(this.apiUrl + 'AddStudentNotificationToken', tokendata).pipe(map(data => {
      return data;
    }));
  }
  CheckAuthentication() {
    return this.http.get<any>(this.AuthapiUrl + 'CheckAuthentication', {}).pipe(map(data => {
      return data;
    }));
  }

  GetCmsPageLink() {
    return this.http.get<any>(this.apiUrl + 'GetCmsPageLinks', {}).pipe(map(data => {
      return data;
    }));
  }
}
